import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectList } from '../main/shared/model/SelectList.model';
import { ClassService } from '../main/shared/services/Class.service';
import { EnrollmentService } from '../main/shared/services/Enrollment.service';
import { SlideInFromLeft } from '../transitions';
import { jsPDF } from "jspdf";
import autoTable, { UserOptions } from 'jspdf-autotable'
import 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { LMSharedDataService } from '../shared/LMSSharedService';
declare let $:any;

@Component({
  selector: 'app-check-enrollment',
  templateUrl: './check-enrollment.component.html',
  styleUrls: ['./check-enrollment.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class CheckEnrollmentComponent implements OnInit {
  classes: Array<SelectList>;
  cls_select:number=0;
  se_select:number;
  sem:number=2;
  sessions: Array<SelectList>;
  enrollStatuses:Array<{maj_id:number,maj_nm:string,offered_subjects,timeTableEntered:number,enrolled_subjects:number}>;

  
  @ViewChild('f') formRef: NgForm;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  You can check the count of all the offered subjects and status of timetable and enrollment in these subjects in any semester 
  of different sessions. </h6>
  <h6 class="text-right">آپ کسی بھی سیشن کے مختلف سمسٹرز میں تمام آفر کردہ سبجیکٹس کی تعداد اور ٹائم ٹیبل اور ان سبجیکٹس میں انرولمنٹ کا اسٹیٹس چیک کر سکتے ہیں۔
</h6>
  `  ;

  constructor(
    private classService: ClassService,
    private enrollmentService: EnrollmentService,
    private toastr:ToastrService,
    private datePipe: DatePipe,
    private lmsSharedService: LMSharedDataService) { }

  ngOnInit(): void {
    this.enrollStatuses=[]
    this.loader=false;
    this.updateClasses();
  }

  
  updateClasses() {
    this.classes = [];
    this.sessions = [];
    this.classService.getTimeTableClasses().subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    this.cls_select=res[0]?.C_CODE;
    this.updateSessions();
    });
  }

  onSessionChange(){
    this.enrollStatuses=[]
    this.formRef.controls['t_no'].reset();
  }

  onT_noChange(){
    this.enrollStatuses=[]  
  }

  updateSessions() {
    this.enrollStatuses=[]
    this.formRef.controls['se_id'].reset();
    this.formRef.controls['t_no'].reset();
    this.sessions = [];

    if (this.cls_select === 0) return;

    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
    .then(session => {
      this.sessions = session
      if (this.formRef) {
        const initialValue = this.sessions[0]?.id;
        this.formRef?.controls['se_id'].setValue(initialValue);
      }
    })
    .catch(error => {
      console.log("Error getting sessions", error)
    })
  }
  loader:boolean=false;
  showStatus(){
    this.loader=true;
    this.enrollStatuses = [];
    this.enrollmentService.checkEnrollmentStatus(this.formRef.value).subscribe((res:{maj_id:number,maj_nm:string,offered_subjects,timeTableEntered:number,enrolled_subjects:number}[]) => {
      res?.forEach(entry => {
        this.enrollStatuses.push(
          {maj_id:entry.maj_id,maj_nm:entry.maj_nm,offered_subjects:entry.offered_subjects,
            timeTableEntered:entry.timeTableEntered,enrolled_subjects:entry.enrolled_subjects});
      });

      this.loader=false;
    }, err => {
      console.log(err)
      this.toastr.error('Some error Occured');
      this.loader=false      
    });
  }


  OnCreatePDF() {    
    this.toastr.info('Generating pdf...',null,{timeOut:120000});
    //togetclassname
    let classname=''
    let session=''
    this.classes.forEach(element => {
      if(element.id==this.formRef.value.c_code)
      {        
        classname=element.nm
      }
    });
    //session
    this.sessions.forEach(element => {
      if(element.id==this.formRef.value.se_id)
      {        
        session=element.nm
      }
    });
  const body = [];
  const header = [];
  
  let k = 0;
  for (let i = 0; i < this.enrollStatuses.length; i++) {
    k++;
    body.push([k,
      this.enrollStatuses[i].maj_nm,
      this.enrollStatuses[i].offered_subjects,
      this.enrollStatuses[i].timeTableEntered,
      this.enrollStatuses[i].enrolled_subjects
    ]);
  }
  var exportDate = this.datePipe.transform(new Date(), 'MMM d,y,h:mm a')
  var image = new Image();
  var page;    
  let cls = classname;    
  let to = this.formRef.value.t_no;
  if(this.formRef.value.t_no==9){
    to = 'COMPLETE';
  }    
  image.src = '../../../assets/images/logo3.png';
  header.push(['Sr #', 'Major Name', 'Offered Subjects', 'TimeTable Entered','Enrolled Subjects']);
  const doc = new jsPDF();
  autoTable(doc, {
    margin: { top: 40, bottom: 10 },
    styles: { fontSize: 8},
    head: header,
    body: body,
    headStyles: {
        halign: 'center'
      },
      columnStyles: {
        0: { halign: "center" },
        1: { halign: "center" },        
        2: { halign: "center" },
        3: { halign: "center" },
        4: { halign: "center" },        
      },
    didDrawPage: function () {
      doc.addImage(image, 177, 2, 25, 25);
      doc.setFontSize(10);
      doc.text(`${exportDate}`, 172, 30);
      doc.setFontSize(20);
      doc.setFont('Arial');
      doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
      doc.setFontSize(12);
      doc.text("STUDENT COUNT IN EACH SUBJECT", 100, 17, { align: 'center' });
      doc.text("CLASS: " + cls?.toUpperCase(), 100, 24, { align: 'center' });
      doc.text("SESSION: " + session +"     SEMESTER: "+to, 100, 31, { align: 'center' });        
      doc.setFontSize(10);
      page = doc.getNumberOfPages();
      doc.text("Note:- Errors and Omissions Excepted", 145, 288);
      doc.text("Directorate of Information Technology", 8, 288);
    }
  });
  var l = (body.length + 65) % 26;
  doc.setFontSize(10);
  for (var i = 1; i <= page; i++) {
    doc.setPage(i);
    doc.text('Page ' + i + " of " + page, 90, 288);
  }
  this.toastr.clear();
  doc.save(`StudentCount `);   
}

  Filter()
    {
      $(document).ready(function(){
        $("#myInput").on("keyup", function() {
          var value = $(this).val().toLowerCase();
          $("#table #values").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
          });
        });
      });
    }
}
