export class TeacherInformationModel {
  constructor( public nm: string, public des: string, public nic: string, public dob: string, public add: string, public pNo: string, 
    public mail: string, public catagory: string, public status: string, public deptNm: string, public gender: string,
    public relig: string, public bld_grp: string, public emrg_num: number,
  ) {
  
  }
}


