import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        // const currentUser = this.authenticationService.currentUserValue;
        // const isLoggedIn = currentUser && currentUser.token;
        // const isApiUrl = request.url.startsWith(environment.apiUrl);
        
        // if (isLoggedIn && isApiUrl) {
        // if (isApiUrl) {
            // console.log("interceptor header attached");
            
        // }

        

        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("gcuid"))}`
            }
        });

        return next.handle(request);
    }
}
