<div class="card" [@SlideInFromLeft]>
    <div class="card-header"  style="padding-top: 10px;">
      <div class=" col-lg-6 col-md-6 form-group">
        Enrollment Status</div>
         <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
        <app-details-button [details]="details"></app-details-button>
    </div>
  </div>
  <div class="card-body">
    <form (ngSubmit)="showStatus()" #f="ngForm">
      <div class="row form-group">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <label for="c_code">Class</label>
          <select #c_code class="form-control" (change)="updateSessions()" name="c_code" id="c_code" [(ngModel)]="this.cls_select">
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <label for="se_id">Session</label>
          <select #se_id class="form-control" (change)="onSessionChange()" name="se_id" id="se_id" [(ngModel)]="this.se_select">
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <label for="t_no">Semester</label>
          <select #t_no class="form-control" (change)="onT_noChange()" name="t_no" id="t_no" [(ngModel)]="sem">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
          </select>
        </div>                

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-3">
        <button type="submit" class="btn btn-primary ml-3">Show Status</button>
        <button *ngIf="this.enrollStatuses.length>0" class="btn btn-danger ml-2" (click)="OnCreatePDF()" type="button"><i class="fa fa-file-pdf-o" for=""></i> Download PDF
        </button> 
        </div>        
      </div>

      <ng-container *ngIf="loader then ifBlock"></ng-container>
        <ng-template #ifBlock>
            <div style="display:flex;justify-content:center; align-items: center;"><span
                    class="loader"></span></div>
        </ng-template>
      
    </form>
    <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
      <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
  </div>
    <table *ngIf="this.enrollStatuses.length>0" class="table table-bordered table-striped" id="table">
      <thead class="thead-dark">
        <tr>
          <th>Major ID</th>
          <th>Major Name</th>
          <th>Subject Offered</th>
          <th>Timetable Entered</th>
          <th>Enrolled Subject</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let st of enrollStatuses" id="values">
          <td>{{st.maj_id}}</td>
          <td>{{st.maj_nm}}</td>
          <td>{{st.offered_subjects}}</td>
          <td>{{st.timeTableEntered}}</td>
          <td>{{st.enrolled_subjects}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
