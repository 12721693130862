import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


export interface Timetable {
  STATUS: number;
  GRP: string;
  G_NM: string;
  C_CODE: number;
  C_NM: string;
  SE_ID: string;
  T_NO: string;
  SECTION: string;
  SUB_CODE: string
  SUB_NM: string;
  DAY: string;
  END_TIME: string;
  ROOM_NM: string;
  START_TIME: string;
  FM_NM: string;
  BLK_NM:string;
  FM_NAME:string;
  NAT_ID: number;
  SESSION: string;
}

export interface Team {
  STATUS: number;
  GRP: string;
  G_NM: string;
  C_CODE: number;
  C_NM: string;
  SE_ID: string;
  T_NO: string;
  SECTION: string;
  SUB_CODE: string;
  SUB_NM: string;
  NAT_ID: number;
  SESSION: string;
}

@Injectable({
  providedIn: 'root'
})
export class TimetableService {

  private timetableData: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]];
  private classes: { c_code, c_nm }[];
  private lockedclasses: { c_code, c_nm }[];
  private teams: Map<number, Team[]>;
  private lockedteams: Map<number, Team[]>;
  private workingClasses: { c_code, c_nm }[];
  private calculteEligibilityClasses: { c_code, c_nm }[];
  private workingTeams: Map<number, Team[]>;
  private calculateEligibilityTeams: Map<number, Team[]>;
  private Submittedclasses: { c_code, c_nm }[];
  private Submittedteams: Map<number, Team[]>;
//for evaluation form to get all classes not use status.
  private allTeams:Map<number,Team[]>;
  private allClasses: { c_code, c_nm }[];


  constructor(private http: HttpClient) {
    this.teams = new Map<number, Team[]>();
    this.lockedteams = new Map<number, Team[]>();
    this.Submittedteams = new Map<number, Team[]>();
    this.calculateEligibilityTeams=new Map<number,Team[]>();
    this.classes = [];
    this.lockedclasses = [];
    this.Submittedclasses = [];
    this.calculteEligibilityClasses=[];
  }

  getTimetable(): [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]] {
    return this.timetableData;
  }

  getClasses(): { c_code: string, c_nm: string }[] {
    return this.classes;
  }

  getTeam(c_code: number): Team[] {
    return this.teams.get(c_code);
  }

  getAllteam(c_code:number):Team[] {
    return this.allTeams.get(c_code);
  }


  getLockedClasses(): { c_code: string, c_nm: string }[] {
    return this.lockedclasses;
  }

  getLockedTeams(c_code: number): Team[] {
    return this.lockedteams.get(c_code);
  }


  getSubmittedClasses(): { c_code: string, c_nm: string }[] {
    return this.Submittedclasses;
  }

  getSubmittedTeam(c_code: number): Team[] {
    return this.Submittedteams.get(c_code);
  }


  getWorkingClasses(){
    return this.workingClasses;
  }

  getWorkingTeams(c_code: number){
    return this.workingTeams.get(c_code);
  }

  getCalculateEligibilityClasses(){
    return this.calculteEligibilityClasses;
  }

  getCalculateEligibilityTeams(c_code: number){
    return this.calculateEligibilityTeams.get(c_code);
  }

  async loadTimetable(timetable: Timetable[]) {
    return new Promise((resolve, reject) => {
      if (!timetable) {
        resolve(true);
        return;
      }
      // for evaluation form
      const allTimeTable:Timetable[] = timetable.filter(x => x.STATUS===1 || x.STATUS===2 || x.STATUS===3 || x.STATUS === 4||x.STATUS === 5);
      
      const liveTimetable: Timetable[] = timetable?.filter(x => x.STATUS === 1);
      // const lockedTimetable: Timetable[] = timetable?.filter(x => x.STATUS === 2 || x.STATUS === 3);
      const lockedTimetable: Timetable[] = timetable?.filter(x => x.STATUS === 3);
      // const workingTimetable: Timetable[] = timetable?.filter(x => x.STATUS === 1 || x.STATUS === 2 || x.STATUS === 3);
      const workingTimetable: Timetable[] = timetable?.filter(x => x.STATUS === 1 || x.STATUS === 3);

      const SubmittedTimetable: Timetable[] = timetable?.filter(x => x.STATUS === 5||x.STATUS === 6);
      const CalculateEligibilityTimetable: Timetable[] = timetable?.filter(x => x.STATUS === 5||x.STATUS === 6||x.STATUS === 3||x.STATUS === 4);

      const monday = liveTimetable?.filter(value1 => !!value1.DAY.toLowerCase().match('monday'));
      const tuesday = liveTimetable?.filter(value1 => !!value1.DAY.toLowerCase().match('tuesday'));
      const wednesday = liveTimetable?.filter(value1 => !!value1.DAY.toLowerCase().match('wednesday'));
      const thursday = liveTimetable?.filter(value1 => !!value1.DAY.toLowerCase().match('thursday'));
      const friday = liveTimetable?.filter(value1 => !!value1.DAY.toLowerCase().match('friday'));

      this.timetableData = [monday, tuesday, wednesday, thursday, friday];
      [this.allTeams, this.allClasses] = this.loadTeams(this.makeTeam(allTimeTable));
      [this.teams, this.classes] = this.loadTeams(this.makeTeam(liveTimetable));
      [this.lockedteams, this.lockedclasses] = this.loadTeams(this.makeTeam(lockedTimetable));
      [this.Submittedteams, this.Submittedclasses] = this.loadTeams(this.makeTeam(SubmittedTimetable));
      [this.workingTeams, this.workingClasses] = this.loadTeams(this.makeTeam(workingTimetable));
      [this.calculateEligibilityTeams, this.calculteEligibilityClasses] = this.loadTeams(this.makeTeam(CalculateEligibilityTimetable));
      
      resolve(true);
    });
  }

  private loadTeams(tms: Team[]): [Map<number, Team[]>, { c_code: string, c_nm: string }[]] {
    let teams = new Map<number, Team[]>();
    let classes: { c_code, c_nm }[] = [];

    tms?.forEach(entry => {
      if (teams.has(entry.C_CODE)) {
        teams.get(entry.C_CODE).push(entry);
      }
      else {
        teams.set(entry.C_CODE, new Array<Team>(entry));
        classes.push({ c_code: entry.C_CODE, c_nm: entry.C_NM });
      }
    });

    return [teams, classes];
  }


  private makeTeam(timetable: Timetable[]): Team[] {
    let tms: Array<Team> = new Array<Team>();

    timetable?.forEach(entry => {
      const x = tms?.find(x => !!(x.C_CODE === entry.C_CODE && x.C_NM == entry.C_NM && x.GRP === entry.GRP &&x.G_NM === entry.G_NM && x.T_NO === entry.T_NO && x.SE_ID === entry.SE_ID && x.SECTION === entry.SECTION &&
        x.STATUS === entry.STATUS && x.SUB_CODE === entry.SUB_CODE && x.SUB_NM === entry.SUB_NM && x.NAT_ID === entry.NAT_ID));

      if (!(x)) {
        tms.push({ GRP: entry.GRP,G_NM:entry.G_NM, C_CODE: entry.C_CODE, C_NM: entry.C_NM, T_NO: entry.T_NO, SE_ID: entry.SE_ID, STATUS: entry.STATUS, SECTION: entry.SECTION, SUB_CODE: entry.SUB_CODE, SUB_NM: entry.SUB_NM, NAT_ID: entry.NAT_ID, SESSION: entry.SESSION });
      }
    });
    return tms;
  }


  getUser() {
    const token = localStorage.getItem('gcuid');
    if (token) {
      const encodedPayload = token.split('.')[1];
      const payload = window.atob(encodedPayload);
      return JSON.parse(payload).user;
    } else {
      return null;
    }
  }
}