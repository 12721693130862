import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class ClassService {

    constructor(private http: HttpClient) {
    }

    // d_id for departments and grp for grous
    getClasses(param) {
        return this.http.post(`${baseUrl}/api/class/getClasses`, param);
    }
    getClassesFeeServer(param) {
        return this.http.post(`${baseUrl}/api/class/getClassesFeeServer`, param);
    }

    getidcClasses(){
        return this.http.get(`${baseUrl}/api/class/getidcClass`);
    }
    //getTmClasses
    getTimeTableClasses(){
        return this.http.get(`${baseUrl}/api/class/getTmClasses`);
    }
    getDepartmentCountStudents(c_code:string,se_id:string){
        return this.http.get(`${baseUrl}/api/class/getDepartmentCountStudents/${c_code}/${se_id}`);

    }

    getAllClasses(){
        return this.http.get(`${baseUrl}/api/class/getAllClasses`);
    }
    
    getAllClassesFeeServer(){
        return this.http.get(`${baseUrl}/api/class/getAllClassesFeeServer`);
    }

    getdepartmentalsubjects(d_id :number,c_code:number){
        return this.http.get(`${baseUrl}/api/class/getdepartmentalsubjects/${d_id}/${c_code}`);
    }

    getteacherclasses(){
        return this.http.get(`${baseUrl}/api/class/getTmClassesoffm`);
    }

    
    getClassSubjectEnrollCount(c_code,se_id,t_no){
        return this.http.get(`${baseUrl}/api/class/getClassSubjectEnrollCount/${c_code}/${se_id}/${t_no}`);
    }

    getDept() {
        return this.http.get(`${baseUrl}/api/class/getDept`);
    }
}