import { Component } from '@angular/core';
import {AuthenticationService} from './auth/_services';
import {Router} from '@angular/router';
import {User} from './auth/_models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  currentUser: User;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(x => {this.currentUser = x;});
  }
}
