import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';

@Injectable({
    providedIn: 'root'
})

export class FeeService {

    constructor(private http: HttpClient) {
    }

    //For Fee Description..
    getFeeDescription() {
        return this.http.get(`${baseUrl}/api/fee/getFeeDesc`);
    }

    getInstallmentNo() {
        return this.http.get(`${baseUrl}/api/fee//getInstallmentNO`);
    }

    getFeeFaculty(c_code) {
        return this.http.get(`${baseUrl}/api/fee/TgetFeeFaculty/${c_code}`);
    }

    getFeeFaculties() {
        return this.http.get(`${baseUrl}/api/fee/getFeeFaculties`);
    }

    addFeeDescription(fee_id, fee_desc) {
        return this.http.get(`${baseUrl}/api/fee/addFeeDesc/${fee_id}/${fee_desc}`);
    }

    deleteFeeDescription(fee_id) {
        return this.http.get(`${baseUrl}/api/fee/deleteFeeDesc/${fee_id}`);
    }

    getBankDescription() {
        return this.http.get(`${baseUrl}/api/fee/getBankDesc`);
    }

    addBankDescription(params) {
        return this.http.post(`${baseUrl}/api/fee/addBankDesc`, params);
    }

    deleteBankDescription(bank_id) {
        return this.http.get(`${baseUrl}/api/fee/deleteBankDesc/${bank_id}`);
    }

    //For Installment Definition Entry
    addFeeDefinition(c_code, fee_id, inst_no, amount, maj_id, year) {
        return this.http.get(`${baseUrl}/api/fee/insertFeeDef/${c_code}/${fee_id}/${inst_no}/${amount}/${maj_id}/${year}`);
    }

    getFeeDefinitionMajor(c_code, maj_id, year, inst) {
        return this.http.get(`${baseUrl}/api/fee/getFeeDefForMajor/${c_code}/${maj_id}/${year}/${inst}`);
    }

    getInstDefForClass(c_code, year, inst) {
        console.log(c_code,year,inst)
        return this.http.get(`${baseUrl}/api/fee/getInstDefForClass${c_code}/${year}/${inst}`);
    }
      
    deleteFeeDefinitionMajor(c_code, maj_id, year, inst, fee_id) {
        return this.http.get(`${baseUrl}/api/fee/deleteFeeDefForMajor/${c_code}/${maj_id}/${year}/${inst}/${fee_id}`);
    }

    getAdmissionBasis() {
        return this.http.get(`${baseUrl}/api/fee/getAdmBases`);
    }


    //For Challan Forms Entry
    GeneratechallanEntry(c_code, maj_id, year, inst, m_lst, b_id) {
        return this.http.get(`${baseUrl}/api/fee/Generatechallan/${c_code}/${maj_id}/${year}/${inst}/${m_lst}/${b_id}`);
    }

    // insertInstallPeriod
    insertInstallmentPeriod(c_code, year, maj_id, inst, pubDate, dueDate, b_id: number, m_lst: number) {
        if (b_id == undefined)
            b_id = null
        if (m_lst == undefined)
            m_lst = null
        return this.http.get(`${baseUrl}/api/fee/insertInstallPeriod/${c_code}/${year}/${maj_id}/${inst}/${pubDate}/${dueDate}/${b_id}/${m_lst}`);
    }

    // loadInstallmentperiod(c_code, year, inst,b_id:number,m_lst:number) {
    //     if(m_lst==undefined) {m_lst=-1}
    //     if(b_id==undefined) {b_id=-1}
    //     return this.http.get(`${baseUrl}/api/fee/getInstallmentPeriod/${c_code}/${year}/${inst}/${b_id}/${m_lst}`);
    // }
    loadInstallmentperiod(c_code, year, maj_id, inst, b_id: number, m_lst: number) {
        return this.http.post(`${baseUrl}/api/fee/getInstallmentPeriod`, { c_code, year, maj_id, inst, b_id, m_lst });
    }

    // delInstallmentperiod(c_code, year, inst,b_id,m_lst) {
    //     if(m_lst==undefined) {m_lst=null}
    //     if(b_id==undefined) {b_id=null}
    //     return this.http.get(`${baseUrl}/api/fee/deleteInstallmentPeriod/${c_code}/${year}/${inst}/${b_id}/${m_lst}`);
    // }

    delInstallmentperiod(c_code, year, maj_id, inst, b_id, m_lst) {

        return this.http.post(`${baseUrl}/api/fee/deleteInstallmentPeriod`, { c_code, year, maj_id, inst, b_id, m_lst });
    }

    //For Sub Installment Entry...
    //
    // insertSubInstallment(c_code,maj_id,year,inst,rn,form_no,pubDate,dueDate,sub_inst_no){
    //     return this.http.get(`${baseUrl}/api/fee/insertSubInstallPeriod/${c_code}/${maj_id}/${year}/${inst}/${rn}/${form_no}/${pubDate}/${dueDate}/${sub_inst_no}`);
    // }


    insertSubInstallment(ch_no, pubDate, sub_inst_no, dueDate, tot_sub_inst) {
        return this.http.post(`${baseUrl}/api/fee/insertSubInstallPeriod`, { ch_no, pubDate, sub_inst_no, dueDate, tot_sub_inst });
    }

    insertSubInstallmentNew(ch_no, sub_inst_no) {
        return this.http.post(`${baseUrl}/api/fee/insertSubInstallPeriodNew`, { ch_no, sub_inst_no });
    }

    deleteSubDues(ch_no) {
        return this.http.get(`${baseUrl}/api/fee/deleteSubDues/${ch_no}`);
    }

    getSubDues(ch_no) {
        return this.http.get(`${baseUrl}/api/fee/getSubDues/${ch_no}`);
    }


    //For Fine Description..
    getFineDescription() {
        return this.http.get(`${baseUrl}/api/fee/getFineDesc`);
    }

    addFineDescription(fine_id, fine_desc) {
        return this.http.get(`${baseUrl}/api/fee/addFineDesc/${fine_id}/${fine_desc}`);
    }

    deleteFineDescription(fine_id) {
        return this.http.get(`${baseUrl}/api/fee/deleteFineDesc/${fine_id}`);
    }


    //For Fine Description..
    getConcCategory() {
        return this.http.get(`${baseUrl}/api/fee/getConcessionCategory`);
    }

    addConcCategory(conc_id, conc_desc, conc_rem) {
        return this.http.get(`${baseUrl}/api/fee/addConcessionCategory/${conc_id}/${conc_desc}/${conc_rem}`);
    }

    deleteConcCategory(conc_id) {
        return this.http.get(`${baseUrl}/api/fee/deleteConcessionCategory/${conc_id}`);
    }


    //For Printing Challan...
    getAllBanks() {
        return this.http.get(`${baseUrl}/api/fee/getBanks`);
    }

    getChallanFormZip(params) {
        return this.http.post(`${baseUrl}/api/fee/showChallanFormZip`, params);
    }

    getChallanForm(c_code, maj_id, year, inst_no, f_no, rn) {
        return this.http.get(`${baseUrl}/api/fee/showChallanForm/${c_code}/${maj_id}/${year}/${inst_no}/${f_no}/${rn}`);
    }

    onLogNm() {
        return this.http.get(`${baseUrl}/api/fee/logNm`);
    }

    onGetDuesInfo(c_code, maj_id, year_in, inst, f_rn, l_rn, b_id, m_list) {
        return this.http.get(`${baseUrl}/api/fee/getDuesInfo/${c_code}/${maj_id}/${year_in}/${inst}/${b_id}/${m_list}/${f_rn}/${l_rn}`);
    }

    //For Date Extenion...
    getChForm(ch_no) {
        return this.http.get(`${baseUrl}/api/fee/showChForm/${ch_no}`);
    }
    getChFormNew(ch_no) {
        return this.http.get(`${baseUrl}/api/fee/showChFormNew/${ch_no}`);
    }

    getSubIntallemntChForm(ch_no) {
        return this.http.get(`${baseUrl}/api/fee/showSubInstallmentChForm/${ch_no}`);
    }
    DateExtend(ch_no, dt_ext, dt_des) {
        return this.http.get(`${baseUrl}/api/fee/extendDate/${ch_no}/${dt_ext}/${dt_des}`);
    }


    //For Subject Fee...
    getSubjForFee(c_code, maj_id, year) {
        return this.http.get(`${baseUrl}/api/fee/getSubjectsForSubjectFee/${c_code}/${maj_id}/${year}`);
    }

    onGetSubjFee(c_code, maj_id, year) {
        return this.http.get(`${baseUrl}/api/fee/getSubjFee/${c_code}/${maj_id}/${year}`);
    }

    deleteSubjectFee(c_code, maj_id, year, sub_code) {
        return this.http.get(`${baseUrl}/api/fee/deleteSubjFee/${c_code}/${maj_id}/${year}/${sub_code}`);
    }


    //For inserting Subject Fee...
    InsertSubjFee(c_code, maj_id, year, sub_code, amount) {
        return this.http.get(`${baseUrl}/api/fee/insertSubjectFee/${c_code}/${maj_id}/${year}/${sub_code}/${amount}`);
    }

    //Merit List
    onGetMlist(c_code, maj_id, year, m_list, b_id) {
        return this.http.get(`${baseUrl}/api/fee/getMlist/${c_code}/${maj_id}/${year}/${m_list}/${b_id}`);
    }

    //Outstanding Dues
    insertOutDues(c_code, maj_id, year, rn, fee_id, amnt, des, dt, inst_no, lastrolno) {
        return this.http.get(`${baseUrl}/api/fee/insertOutDues/${c_code}/${maj_id}/${year}/${rn}/${fee_id}/${amnt}/${des}/${dt}/${inst_no}/${lastrolno}`);
    }

    getFeeAcc(c_code, bnk, feeAcc, acc_No, year) {
        return this.http.get(`${baseUrl}/api/fee/getFeeAcc/${c_code}/${bnk}/${feeAcc}/${acc_No}/${year}`);
    }

    onGetOutDues(c_code, maj_id, year, rn, inst_no, lastrolno) {
        return this.http.get(`${baseUrl}/api/fee/getOutDues/${c_code}/${maj_id}/${year}/${rn}/${inst_no}/${lastrolno}`);
    }

    deleteOutDues(c_code, maj_id, year, rn, fee_id, inst_no) {
        return this.http.get(`${baseUrl}/api/fee/deleteOutDues/${c_code}/${maj_id}/${year}/${rn}/${fee_id}/${inst_no}`);
    }

    //Fee Report
    onGetDuesReport(c_code, maj_id, year, inst_no, b_id, m_list) {
        return this.http.get(`${baseUrl}/api/fee/getDuesReport/${c_code}/${maj_id}/${year}/${inst_no}/${b_id}/${m_list}`);
    }

    onGetNonSubDuesReport(c_code, maj_id, year, inst_no, b_id, m_list) {
        return this.http.get(`${baseUrl}/api/fee/getNonSubDuesReport/${c_code}/${maj_id}/${year}/${inst_no}/${b_id}/${m_list}`);
    }

    onGetSubDuesReport(c_code, maj_id, year, inst_no, b_id, m_list) {
        return this.http.get(`${baseUrl}/api/fee/getSubDuesReport/${c_code}/${maj_id}/${year}/${inst_no}/${b_id}/${m_list}`);
    }

    //Fee Refund
    onGetRefund(c_code, maj_id, year, rn) {
        return this.http.get(`${baseUrl}/api/fee/getRefund/${c_code}/${maj_id}/${year}/${rn}`);
    }

    onInsertRefund(c_code, maj_id, year, rn, fee_id, amnt, des, dt) {
        return this.http.get(`${baseUrl}/api/fee/insertRefund/${c_code}/${maj_id}/${year}/${rn}/${fee_id}/${amnt}/${des}/${dt}`);
    }

    deleteFeeRefund(c_code, maj_id, year, rn, fee_id) {
        return this.http.get(`${baseUrl}/api/fee/deleteFeeRefund/${c_code}/${maj_id}/${year}/${rn}/${fee_id}`);
    }

    //Fee Concession
    onGetConcDesc(conc_id) {
        return this.http.get(`${baseUrl}/api/fee/getConcDesc/${conc_id}`);
    }

    onFeeConcession(ch_no, conc_id) {
        return this.http.get(`${baseUrl}/api/fee/feeConcession/${ch_no}/${conc_id}`);
    }

    //Financial Aid Description
    getSchpType() {
        return this.http.get(`${baseUrl}/api/fee/getSchpType`);
    }

    getSchpCat(schp_id) {
        return this.http.get(`${baseUrl}/api/fee/getSchpTypeCat/${schp_id}`);
    }

    addSchpTypeCat(schp_id, fa_id, fa_desc) {
        return this.http.get(`${baseUrl}/api/fee/addSchpTypeCat/${schp_id}/${fa_id}/${fa_desc}`);
    }

    deleteSchpTypeCat(schp_id, fa_id) {
        return this.http.get(`${baseUrl}/api/fee/deleteSchpTypeCat/${schp_id}/${fa_id}`);
    }

    //Class Bank Relation
    insertFeeAcc(c_code, year, fee_id, bnk_id, acc_no, start_dt, end_dt, oper, ctgry, rem) {
        return this.http.post(`${baseUrl}/api/fee/insertFeeAcc`, { c_code, year, fee_id, bnk_id, acc_no, start_dt, end_dt, oper, ctgry, rem });
    }

    deleteClassBank(c_code, bnk, fee_id, year) {
        return this.http.get(`${baseUrl}/api/fee/deleteClassBank/${c_code}/${bnk}/${fee_id}/${year}`);
    }



    //For Banking Challan Form
    getBankChallanForm(ch_no) {
        return this.http.get(`${baseUrl}/api/fee/challan_for_bank/${ch_no}`);
    }

    //Eligibility
    calReElig(params) {
        return this.http.post(`${baseUrl}/api/fee/calReElig`, params);
    }

    sessionsEnroll(params) {
        return this.http.post(`${baseUrl}/api/fee/sessionsEnroll`, params);
    }

    subEnroll(params) {
        return this.http.post(`${baseUrl}/api/fee/subEnroll`, params);
    }

    updateAttendance(params) {
        return this.http.post(`${baseUrl}/api/fee/updateAttendance`, params);
    }

    reElig(params) {
        return this.http.post(`${baseUrl}/api/fee/reElig`, params);
    }


    //For Schp sub base
    getSchpSubCat(schp_id) {
        return this.http.get(`${baseUrl}/api/fee/getSchpSubCat/${schp_id}`);
    }

    addSchpSubCat(schp_id, fa_id, fa_desc) {
        return this.http.get(`${baseUrl}/api/fee/addSchpSubCat/${schp_id}/${fa_id}/${fa_desc}`);
    }

    deleteSchpSubCat(schp_id, fa_id) {
        return this.http.get(`${baseUrl}/api/fee/deleteSchpSubCat/${schp_id}/${fa_id}`);
    }

    //For Schp Info
    getSchpBoard() {
        return this.http.get(`${baseUrl}/api/fee/getBoardDesc`);
    }

    getDistrict() {
        return this.http.get(`${baseUrl}/api/fee/getDistrict`);
    }

    addSchpInfo(schp_id, cat_id, district, brd_id, c_code, year, prt, res_rate, non_res_rate, frm_dt, to_dt, drft, drft_dt, drft_amnt, acc_num, amnt, dt, l_no, l_dt) {
        return this.http.get(`${baseUrl}/api/fee/addSchpInfo/${schp_id}/${cat_id}/${district}/${brd_id}/${c_code}/${year}/${prt}/${res_rate}/${non_res_rate}/${frm_dt}/${to_dt}/${drft}/${drft_dt}/${drft_amnt}/${acc_num}/${amnt}/${dt}/${l_no}/${l_dt}`);
    }

    //For Schp Category
    addSchpType(schpcat_id, schpcat_desc) {
        return this.http.get(`${baseUrl}/api/fee/addSchpType/${schpcat_id}/${schpcat_desc}`);
    }

    deleteSchpType(schpcat_id) {
        return this.http.get(`${baseUrl}/api/fee/deleteSchpType/${schpcat_id}`);
    }

    //For Schp Award
    awardSchp(ch_no, fee_id, amount, schp_amnt) {
        return this.http.get(`${baseUrl}/api/fee/awardSchp/${ch_no}/${fee_id}/${amount}/${schp_amnt}`);
    }

    loadSchpDues(ch_no) {
        return this.http.post(`${baseUrl}/api/fee/loadSchpDues`, {ch_no});
    }

    //For Schp Application Form
    schpStdtInfo(params) {
        return this.http.post(`${baseUrl}/api/fee/schpStdtInfo`, params);
    }

    schpStdtAwardList(c_code, se_id, schp_id, cat_id, task) {
        return this.http.get(`${baseUrl}/api/fee/loadSchpStdtList/${c_code}/${se_id}/${schp_id}/${cat_id}/${task}`);
    }

    addSchpStdt(rnA, domicile, schp_id, cat_id, brd_id, acad_year, inst_no, prev_rn, prev_exm, prev_inst, tot_mrks, obt_mrks, income, tax, rev, g_nm, g_nic, rel, district, dt_of_intrvu, f_nic) {
        return this.http.get(`${baseUrl}/api/fee/addSchpStdt/${rnA}/${domicile}/${schp_id}/${cat_id}/${brd_id}/${acad_year}/${inst_no}/${prev_rn}/${prev_exm}/${prev_inst}/${tot_mrks}/${obt_mrks}/${income}/${tax}/${rev}/${g_nm}/${g_nic}/${rel}/${district}/${dt_of_intrvu}/${f_nic}`);
    }

    deleteSchpStdt(ch_no) {
        return this.http.get(`${baseUrl}/api/fee/deleteSchpStdt/${ch_no}`);
    }

    //For Manual Challan
    onInsertMiscChallan(sub_by, des, due_dt, acc_no, fee_id, amnt) {
        return this.http.get(`${baseUrl}/api/fee/insertMiscChallan/${sub_by}/${des}/${due_dt}/${acc_no}/${fee_id}/${amnt}`);
    }

    onGetManualChallan(sub_by, des, due_dt) {
        return this.http.get(`${baseUrl}/api/fee/getManualChallan/${sub_by}/${des}/${due_dt}`);
    }


    // Dues Adjustments
    onDuesAdjustments(ch1, ch2) {
        return this.http.get(`${baseUrl}/api/fee/adjustDuesRecord/${ch1}/${ch2}`);
    }

    getOldChallanForm(ch_no) {
        return this.http.get(`${baseUrl}/api/fee/showOldPaidChallan/${ch_no}`);
    }
    // getChallanFormZip(params){
    //     return this.http.post(`${baseUrl}/api/fee/showChallanFormZip`,params);
    // }
    fetchDataFromSAOPortal(year: any, program: any, majorId: any, baseId: any, meritList: any) {

        // let httpHeaders = new HttpHeaders();
        // httpHeaders = httpHeaders.append("appId", "2");
        // httpHeaders = httpHeaders.append("appKey", "b0a363bcc32161433cbd32001a9405f1");


        // let queryParams = new HttpParams();
        // queryParams = queryParams.append("year", year)
        // queryParams = queryParams.append("program", program)
        // queryParams = queryParams.append("majorId", majorId)
        // queryParams = queryParams.append("baseId", baseId);
        // queryParams = queryParams.append("meritList", meritList);

        // return this.http.post<boolean>(`http://www.gcuonline.pk/apis/MeritList/data`, queryParams, { headers: httpHeaders });
        return this.http.post(`${baseUrl}/api/fee/fetchDataFromAdmissionSystem`, { year, program, majorId, baseId, meritList });
    }

    InsertDataInLmsFromAdmissionSystem(log_id_in: number, f_no_in: number, nm_in: string, gender_in: string, f_nm_in: string, nic_in: string,
        dob_in: string, ph1_in: string, email_in: string, add1_in: string, maj_id_in: number, b_id_in: number, c_code_in: number, m_list_in: number, sr_no: number,
        year_in: number, set_no: number, relig: string, shift: string, ph2: string,
        tehsilId: number, districtId: number, add2: string, matricObt: string, matricTotal: string, matricBrd: number, matricRn: string,
        interObt: string, interTotal: string, interBrd: number,
        interRn: string, bsHonsObt: string, bsHonsTot: string, honsUni: number, honsRn: string,f_nic: string,
        matricPassYear: string,matricExamNature: string,interPassYear: string,honsPassYear: string,provinceId: string, meritListCtgry:string, fatherEmail: string, fatherOccp: string, fatherQual: string, fatherOffice: string, fatherIncome: number, fatherStatus: string, motherName: string, motherNic: string, motherContact: string, motherEmail: string, motherQual: string, motherOccp: string, motherOffice: string, motherIncome: number ,motherStatus: string, guardName: string, guardAddr:string, guardNic:string, guardEmail: string, countryID: number
        ) {

        return this.http.post(`${baseUrl}/api/fee/InsertDataInLmsFromAdmissionSystem`,
            {
                log_id_in, f_no_in, nm_in, gender_in, f_nm_in, nic_in,
                dob_in, ph1_in, email_in, add1_in, maj_id_in, b_id_in, c_code_in, m_list_in, sr_no,
                year_in, set_no, relig, shift, ph2,
                tehsilId, districtId, add2, matricObt, matricTotal, matricBrd, matricRn, interObt, interTotal, interBrd,
                interRn, bsHonsObt, bsHonsTot, honsUni, honsRn,f_nic,matricPassYear,matricExamNature,interPassYear,
                honsPassYear,provinceId,meritListCtgry, fatherEmail, fatherOccp, fatherQual, fatherOffice, fatherIncome, fatherStatus, motherName, motherNic, motherContact, motherEmail, motherQual, motherOccp, motherOffice, motherIncome,motherStatus, guardName, guardAddr, guardNic, guardEmail, countryID
            }
        )
    }

    InsertDataInFeeFromAdmissionSystem(log_id_in: number, f_no_in: number, nm_in: string, gender_in: string, f_nm_in: string, nic_in: string,
        dob_in: string, ph1_in: string, email_in: string, add1_in: string, maj_id_in: number, b_id_in: number, c_code_in: number, m_list_in: number, sr_no: number,
        year_in: number, set_no: number, relig: string, shift: string, ph2: string,
        tehsilId: number, districtId: number, add2: string, matricObt: string, matricTotal: string, matricBrd: number, matricRn: string,
        interObt: string, interTotal: string, interBrd: number,
        interRn: string, bsHonsObt: string, bsHonsTot: string, honsUni: number, honsRn: string,f_nic: string,
        matricPassYear: string,matricExamNature: string,interPassYear: string,honsPassYear: string,provinceId: string,meritListCtgry: string, fatherEmail: string, fatherOccp: string, fatherQual: string, fatherOffice: string, fatherIncome: number, fatherStatus: string, motherName: string, motherNic: string, motherContact: string, motherEmail: string, motherQual: string, motherOccp: string, motherOffice: string, motherIncome: number ,motherStatus: string, guardName: string, guardAddr:string, guardNic:string, guardEmail: string, countryID: number
        ) {

        return this.http.post(`${baseUrl}/api/fee/InsertDataInFeeFromAdmissionSystem`,
            {
                log_id_in, f_no_in, nm_in, gender_in, f_nm_in, nic_in,
                dob_in, ph1_in, email_in, add1_in, maj_id_in, b_id_in, c_code_in, m_list_in, sr_no,
                year_in, set_no, relig, shift, ph2,
                tehsilId, districtId, add2, matricObt, matricTotal, matricBrd, matricRn, interObt, interTotal, interBrd,
                interRn, bsHonsObt, bsHonsTot, honsUni, honsRn,f_nic,matricPassYear,matricExamNature,interPassYear,
                honsPassYear,provinceId,meritListCtgry, fatherEmail, fatherOccp, fatherQual, fatherOffice, fatherIncome, fatherStatus, motherName, motherNic, motherContact, motherEmail, motherQual, motherOccp, motherOffice, motherIncome,motherStatus, guardName, guardAddr, guardNic, guardEmail, countryID
            }
        )
    }

    Check_Paid_Unpaid_Chalans(c_code: string, maj_id: string, year: string, sem: string, ch_no: string) {
        return this.http.post(`${baseUrl}/api/fee/Check_Paid_Unpaid_Chalans`, { c_code, maj_id, year, sem, ch_no })
    }
    getSessionYear() {
        return this.http.post(`${baseUrl}/api/fee/getSessionYear`, {})
    }

    InsertDataInLmsFromAdmissionSystemFEESERVER(log_id_in: number, f_no_in: number, nm_in: string, gender_in: string, f_nm_in: string, nic_in: string,
        dob_in: string, ph1_in: string, email_in: string, add1_in: string, maj_id_in: number, b_id_in: number, c_code_in: number, m_list_in: number, sr_no: number,
        year_in: number, set_no: number, relig: string, shift: string) {

        return this.http.post(`${baseUrl}/api/fee/InsertDataInLmsFromAdmissionSystemFEESERVER`,
            {
                log_id_in, f_no_in, nm_in, gender_in, f_nm_in, nic_in,
                dob_in, ph1_in, email_in, add1_in, maj_id_in, b_id_in, c_code_in, m_list_in, sr_no,
                year_in, set_no, relig, shift
            }
        )
    }

    T_getFaculty(c_code: number) {
        return this.http.post(`${baseUrl}/api/fee/T_getFaculty`, { c_code })
    }
    ChallanNotGenerated(c_code: number, maj_id: number, year: number, inst: number){
        return this.http.get(`${baseUrl}/api/abdulGenerateChallan/not_generated?c_code=${c_code}&maj_id=${maj_id}&year=${year}&inst=${inst}`);
      }
      
    getChFormByRn(c_code, maj_id, inst_no, year, rn, ch_no) {
        return this.http.post(`${baseUrl}/api/fee/getChFormByRn`, { c_code, maj_id, inst_no, year, rn, ch_no })
    }
    getManualChallanData(c_code, maj_id, year_in, rn) {
        return this.http.post(`${baseUrl}/api/fee/getManualChallanData`, { c_code, maj_id, year_in, rn })
    }
    getUnassignedChallan() {
        return this.http.post(`${baseUrl}/api/fee/getUnassignedChallan`, {})
    }
    InsertManualEntryinDues(ch_no, c_code, inst_no, maj_id, year_in, fee_id, debit, dueDate, des_in, rn) {
        return this.http.post(`${baseUrl}/api/fee/InsertManualEntryinDues`, { ch_no, c_code, inst_no, maj_id, year_in, fee_id, debit, dueDate, des_in, rn })
    }
    //modified by Mehtab on dated 12-03-2024 added two new columns ,ban_id checque_no_in for schp
    InsertDuesForScholarShipStdt(ch_no_in, fee_id_in, act_amnt_in, schp_amnt_in, schp_id_in, schp_cat_in, rem_in,bank_id_in,checque_no_in) {
        return this.http.post(`${baseUrl}/api/fee/InsertDuesForScholarShipStdt`, { ch_no_in, fee_id_in, act_amnt_in, schp_amnt_in, schp_id_in, schp_cat_in, rem_in,bank_id_in,checque_no_in })
    }
    LoadSubInst(ch_no, c_code, maj_id, rn, inst_no) {
        return this.http.post(`${baseUrl}/api/fee/LoadSubInst`, { ch_no, c_code, maj_id, rn, inst_no })
    }
    PrintSubIntallmentChForm(ch_no) {
        return this.http.post(`${baseUrl}/api/fee/PrintSubIntallmentChForm`, { ch_no })
    }
    UpdateDueDateForSubInstall(dueDate, ch_no) {
        return this.http.post(`${baseUrl}/api/fee/UpdateDueDateForSubInstall`, { dueDate, ch_no })
    }
    ManualFeeConcession(ch_no, con_id, fee_id, amnt_in, des, tot_amnt) {
        return this.http.post(`${baseUrl}/api/fee/ManualFeeConcession`, { ch_no, con_id, fee_id, amnt_in, des, tot_amnt })
    }
    CheckStdInformation(c_code, year, inst_no, rn) {
        return this.http.post(`${baseUrl}/api/fee/CheckStdInformation`, { c_code, year, inst_no, rn })
    }
    DelManageSubInstallment(ch_no) {
        return this.http.post(`${baseUrl}/api/fee/DelManageSubInstallment`, { ch_no })
    }
    maxSchpInfo() {
        return this.http.get(`${baseUrl}/api/fee/getMaxSchpInfo`);
    }
    loadSchpInfo(c_code, year, prt, schp_id, cat_id, task) {
        return this.http.post(`${baseUrl}/api/fee/loadSchpInfo`, { c_code, year, prt, schp_id, cat_id, task });
    }
    FetchClassBankRelation(c_code, bnkid, year) {
        return this.http.post(`${baseUrl}/api/fee/FetchClassBankRelation`, { c_code, bnkid, year });
    }
    addSchpExternal(rolno, schp_amnt, schp_id, cat_id) {
        return this.http.post(`${baseUrl}/api/fee/schpAwardExternal`, { rolno, schp_amnt, schp_id, cat_id });
    }
    sfaoLetterData(schp_id, cat_id, brd_id, cheq, dt) {
        return this.http.post(`${baseUrl}/api/fee/sfaoLetterData`, { schp_id, cat_id, brd_id, cheq, dt });
    }
    VerifyMajorFormStdt(year_in,newyear, old_f_no, old_c_code, new_c_code, new_maj_id, new_m_list, new_b_id) {
        return this.http.post(`${baseUrl}/api/fee/VerifyMajorFormStdt`, { year_in, old_f_no, old_c_code, new_c_code, new_maj_id, new_m_list, new_b_id,newyear });
    }
    SfaostudentBudget(schp_type, schp_cat, c_code, gen, start_dt, end_dt,bank_id_in) {
        return this.http.post(`${baseUrl}/api/fee/SFAOStudentBudget`, {schp_type, schp_cat, c_code, gen, start_dt, end_dt,bank_id_in});
    }
    SfaostudentBudgetnew(schp_type, schp_cat, c_code, gen, start_dt, end_dt) {
        return this.http.post(`${baseUrl}/api/fee/SFAOStudentBudgetnew`, {schp_type, schp_cat, c_code, gen, start_dt, end_dt});
    }
    DeleteFeeConc(ch_no) {
        return this.http.post(`${baseUrl}/api/fee/DeleteFeeConc`,{ch_no});
    }
    LoadSchpHistory(ch_no) {
        return this.http.post(`${baseUrl}/api/fee/T_loadSchpHistory`,{ch_no});
    }
    LockChallanByFeeSec(ch_no) {
        return this.http.post(`${baseUrl}/api/fee/LockChallanByFeeSec`,{ch_no});
    }
    UnlockChallanByFeeSec(ch_no) {
        return this.http.post(`${baseUrl}/api/fee/UnlockChallanByFeeSec`,{ch_no});
    }
    DeleteGeneratedChallans(c_code,maj_id,year,inst_no,m_list,b_id) {
        return this.http.post(`${baseUrl}/api/fee/DeleteGeneratedChallans`,{c_code,maj_id,year,inst_no,m_list,b_id});
    }
    parentChallanBySubChallan(sch_no) {
        return this.http.post(`${baseUrl}/api/fee/parentChallanBySubChallan`, {sch_no});
    }
    GetOutStandDues(ch_no) {
        return this.http.post(`${baseUrl}/api/fee/GetOutStandDues`, {ch_no});
    }
    AddOutstandDues(ch_no,fine_id,amnt) {
        return this.http.post(`${baseUrl}/api/fee/AddOutstandDues`, {ch_no,fine_id,amnt});
    }
    CheckStdInformationByCh(ch_no) {
        return this.http.post(`${baseUrl}/api/fee/CheckStdInformationByCh`, { ch_no })
    }
    getOutstandingEntriesFeeIds() {
        return this.http.post(`${baseUrl}/api/fee/getOutstandingEntriesFeeIds`, {})
    }
    getStdNameByRn(c_code,year,rn)
    {
        return this.http.post(`${baseUrl}/api/fee/getStdNameByRn`, {c_code,year,rn})
    }
    insertOutStdEntry(c_code,year,rn,fine_id,amnt,fine_entry_dt,des)
    {
        return this.http.post(`${baseUrl}/api/fee/insertOutStdEntry`, {c_code,year,rn,fine_id,amnt,fine_entry_dt,des})  
    }
    getOutStdEntries(c_code,year,fine_entry_dt)
    {
        return this.http.post(`${baseUrl}/api/fee/getOutStdEntries`, {c_code,year,fine_entry_dt})  
    }
    deleteOutStdEntry(c_code,rn,fine_id,year,fine_entry_date)
    {
        return this.http.post(`${baseUrl}/api/fee/deleteOutStdEntry`, {c_code,rn,fine_id,year,fine_entry_date})  
    }
    getOutDuesRollnos(c_code,year,fine_entry_date,fine_entry_date_end,fine_id)
    {
        return this.http.post(`${baseUrl}/api/fee/getOutDuesRollnos`, {c_code,year,fine_entry_date,fine_entry_date_end,fine_id})  
    }
    generateOutstandDues(c_code,year,fine_entry_date,fine_entry_date_end,due_date,f_rn,l_rn,fine_id)
    {
        return this.http.post(`${baseUrl}/api/fee/generateOutstandDues`, {c_code,year,fine_entry_date,fine_entry_date_end,due_date,f_rn,l_rn,fine_id})  
    }
    
    showChallanFormOutStdDues(c_code,year,f_rn,l_rn,fine_entry_date,fine_entry_date_end){
        return this.http.post(`${baseUrl}/api/fee/showChallanFormOutStdDues`, {c_code,year,f_rn,l_rn,fine_entry_date,fine_entry_date_end})  
    }
    ChangeDueDateForOutStdEntries(c_code,year,fine_entry_date,fine_entry_date_end,f_rn,l_rn,fine_id,due_date)
    {
        return this.http.post(`${baseUrl}/api/fee/ChangeDueDateForOutStdEntries`, {c_code,year,fine_entry_date,fine_entry_date_end,f_rn,l_rn,fine_id,due_date})  
    }
    deleteGeneratedOutDues(c_code,year,f_rn,l_rn,fine_entry_date,fine_entry_date_end,fine_id)
    {
        return this.http.post(`${baseUrl}/api/fee/deleteGeneratedOutDues`, {c_code,year,f_rn,l_rn,fine_entry_date,fine_entry_date_end,fine_id})  
    }
    getFeeRefundFeeIds() {
        return this.http.post(`${baseUrl}/api/fee/getFeeRefundFeeIds`, {})
    }
    getFeeRefundDes() {
        return this.http.post(`${baseUrl}/api/fee/getFeeRefundDes`, {})
    }
    insertFeeRefundEntries(c_code,year,rn,fine_id,amnt,fine_entry_dt,des)
    {
        return this.http.post(`${baseUrl}/api/fee/insertFeeRefundEntries`, {c_code,year,rn,fine_id,amnt,fine_entry_dt,des})  
    }
    getFeeRefundEntries(c_code,year,fine_entry_dt)
    {
        return this.http.post(`${baseUrl}/api/fee/getFeeRefundEntries`, {c_code,year,fine_entry_dt})  
    }
    deleteFeeRefundStdEntry(c_code,rn,fine_id,year,fine_entry_date)
    {
        return this.http.post(`${baseUrl}/api/fee/deleteFeeRefundStdEntry`, {c_code,rn,fine_id,year,fine_entry_date})  
    }
    
    deleteSchpEntry(ch_no:string)
    {
        return this.http.post(`${baseUrl}/api/fee/deleteSchpEntry`, {ch_no})  
    }

    getFeeDetails(rolNo:any, f_no:any, ch_no: any){
        return this.http.post(`${baseUrl}/api/fee/getFeeDetails`, {rolNo, f_no, ch_no});
    }

    updateRegistrationNumberFee(f_no:any, reg_no: any, c_code:any, maj_id: any, year: any){
        return this.http.post(`${baseUrl}/api/fee/T_updateRegistrationNumberFee`, {f_no, reg_no, c_code, maj_id, year});
    }

    updateRegistrationNumberLms(f_no:any, reg_no:any, c_code:any, maj_id: any, year: any){
        return this.http.post(`${baseUrl}/api/fee/T_updateRegistrationNumberLms`, {f_no, reg_no, c_code, maj_id, year});
    }

    SFAOUpdateChequeNo(ch_no:any,inst_no:number,cheque_no:any,schp_type:number,schp_cat:number){
        return this.http.post(`${baseUrl}/api/fee/T_SFAO_updateChequeNo`, {ch_no,inst_no,cheque_no,schp_type,schp_cat});
    }

    SFAOUpdateSchpEntryDt(ch_no:any,exDate:any){
        return this.http.post(`${baseUrl}/api/fee/T_SFAO_updateSchpEntryDt`, {ch_no,exDate});
    }

    feeAnnouncement(c_code, year, maj_id, inst_no, rn){
        return this.http.post(`${baseUrl}/api/fee/T_feeAnnouncement`, {c_code, year, maj_id, inst_no, rn});
    }
    
    checkStdtData(c_code, maj_id, year, m_lst, b_id){
        return this.http.post(`${baseUrl}/api/fee/checkStdtData`, {c_code, maj_id, year, m_lst, b_id});
    }

    getUnpaidChallansForManualChallan(rolno:string, year: number, c_code: number, maj_id: number) {
        return this.http.post(`${baseUrl}/api/fee/getUnpaidChallansForManualChallan`, {rolno: rolno, year: year, c_code: c_code, maj_id: maj_id});
    }
}
