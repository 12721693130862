import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { FormsModule}   from '@angular/forms';
import { CommonModule } from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthComponent} from './auth/auth.component';
import * as fromApp from '../app/store/app.reducers';
import {ChartsModule} from 'ng2-charts';
import {StoreModule} from '@ngrx/store';
import {ReactiveFormsModule} from '@angular/forms';
import {JwtInterceptor, ErrorInterceptor, AuthGuard} from './auth/_helpers';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import {DatePipe} from '@angular/common'; 
import { AlertComponent } from './main/alert/alert.component';
import { RolesService } from './auth/_services/Roles.service';
import {wsUrl} from '../axios';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { CheckEnrollmentComponent } from './check-enrollment/check-enrollment.component';
import { DetailsButtonModule } from './details-button/details-button.module';
// import { MatIconModule } from '@angular/material/icon';
const config: SocketIoConfig = { url: wsUrl, options: {} };


export function init_app(appConfigService: RolesService) {
  return () => appConfigService.load(false);
}
@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    AuthComponent,
    CheckEnrollmentComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ChartsModule,
    StoreModule.forRoot(fromApp.appReducers),
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    ToastrModule.forRoot(),
    BrowserModule,
    SocketIoModule.forRoot(config),
    DetailsButtonModule
  ],
  exports:[
    DetailsButtonModule
  ],
  providers: [
    DatePipe,
    AuthGuard,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [RolesService], multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
