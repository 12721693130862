import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { of } from 'rxjs';
import { User } from '../_models';
import { baseUrl } from './../../../axios';
import { Router } from '@angular/router';
import { Timetable, TimetableService } from 'src/app/main/shared/services/Timetable.service';

const INITIAL_USER: User = {
    username: null,
    role: null,
    department: null,
    roles: {
        group: null,
        isAdmin: false,
        isTeacher: false,
        canManageTimetable: false,
        canViewStudentProfile: false,
        canEnrollRepeater: false,
        isExamCell: false,
        canAddSubject: false,
        canEnroll: false,
        canAllocateSection: false,
        canReadmission:false,
        canIDC: false,
        canFeeStructure: false,
        stdDetail: false,
        superAdmin:false,
        financialOffice:false,
        endownmentFund:false,
        isQEC:false,
        isHod:false,
        isDashboard: false
    }
};

@Injectable({ providedIn: 'root' })
export class RolesService {

    private user: User = INITIAL_USER;

    constructor(public http: HttpClient,
        private router: Router,
        private timetableService: TimetableService
    ) { }


    public get credentials(): User {
        return this.user;
    }

    async loadRole(data: { GRP: number, ROLE_DES: string }, timetable: Timetable[]) {
        // console.log("ROLES: ", data)
        if (data) {
            this.user.roles.group = (data?.GRP);
            this.user.roles.isAdmin = (data.ROLE_DES?.charAt(0) == "1");
            this.user.roles.isTeacher = (data.ROLE_DES?.charAt(1) == "1");
            this.user.roles.canManageTimetable = (data?.ROLE_DES.charAt(2) == "1");
            this.user.roles.canViewStudentProfile = (data?.ROLE_DES.charAt(3) == "1");
            this.user.roles.canEnrollRepeater = (data.ROLE_DES?.charAt(4) == "1");
            this.user.roles.isExamCell = (data.ROLE_DES?.charAt(5) == "1");
            this.user.roles.canAddSubject = (data.ROLE_DES?.charAt(6) == "1");
            this.user.roles.canEnroll = (data.ROLE_DES?.charAt(7) == "1");
            this.user.roles.canAllocateSection = (data.ROLE_DES?.charAt(8) == "1");
            this.user.roles.canReadmission = (data.ROLE_DES?.charAt(9) == "1");
            this.user.roles.canIDC = (data.ROLE_DES?.charAt(10) == "1");
            this.user.roles.canFeeStructure = (data.ROLE_DES?.charAt(11) == "1");
            this.user.roles.stdDetail = (data.ROLE_DES?.charAt(14) == "1");
            this.user.roles.superAdmin = (data.ROLE_DES?.charAt(15) == "1");
            this.user.roles.financialOffice = (data.ROLE_DES?.charAt(16) == "1");
            this.user.roles.endownmentFund = (data.ROLE_DES?.charAt(17) == "1");
            this.user.roles.isQEC = (data.ROLE_DES?.charAt(18) == "1");
            this.user.roles.isHod = (data.ROLE_DES?.charAt(19) == "1");
            this.user.roles.isDashboard = (data.ROLE_DES?.charAt(20) == "1");
            await this.timetableService.loadTimetable(timetable);
        }
    }

    load(moveToMain: boolean) {
        return new Promise((resolve, reject) => {

            const usr = this.getUser();
            this.user.role = usr?.ROLE;

            if (usr && usr?.ROLE === 'FMR') {
                this.http.get(`${baseUrl}/api/teacher/getRoles`).subscribe(async (res: [{ GRP: number, ROLE_DES: string }, Timetable[]]) => {
                    if (res) {
                        await this.loadRole(res[0],res[1] || null);
                        if (moveToMain) {
                            this.router.navigate(['/main']);
                        }
                        resolve(true);
                    }
                }, err => {
                    reject();
                    console.log(err);
                });
            } else if(usr && usr.ROLE==='STDR'){
                this.http.get(`${baseUrl}/api/student/getTimetable`).subscribe(async (res: Timetable[])=>{
                    await this.timetableService.loadTimetable(res);
                    this.setRole('STDR');
                    resolve(true);
                },err=>{
                    reject();
                    console.log(err);
                });
            }else{
                resolve(true);
            }
        });
    }

    getRoles() {
        return this.user.role;
    }
    setDepartment(d_id: string) {
        this.user.department = d_id;
    }

    setRole(role: string) {
        this.user.role = role;
    }

    destroy() {
        this.user = INITIAL_USER;
    }

    getUser() {
        const token = localStorage.getItem('gcuid');
        if (token) {
            const encodedPayload = token.split('.')[1];
            const payload = window.atob(encodedPayload);
            return JSON.parse(payload).user;
        } else {
            return null;
        }
    }
}